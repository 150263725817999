import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "invite" */ '../views/qianyan.vue')
  },
  {
    path: '/aibot',
    name: 'Home',
    meta: { title: 'Aibot分享' },
    component: () => import(/* webpackChunkName: "invite" */ '../views/aibot.vue')
  },
  {
    path: '/airobot',
    name: 'Home',
    meta: { title: 'airobot分享' },
    component: () => import(/* webpackChunkName: "invite" */ '../views/airobot.vue')
  },
  {
    path: '/shenji',
    name: 'Home',
    meta: { title: '神机ai分享' },
    component: () => import(/* webpackChunkName: "invite" */ '../views/shenji.vue')
  },
  {
    path: '/wendao',
    name: 'Home',
    meta: { title: '问道ai分享' },
    component: () => import(/* webpackChunkName: "invite" */ '../views/wendao.vue')
  },
  {
    path: '/aimeta',
    name: 'Home',
    meta: { title: 'aimeta分享' },
    component: () => import(/* webpackChunkName: "invite" */ '../views/aimeta.vue')
  },
  {
    path: '/kingCall',
    name: 'Kingcall',
    component: () => import(/* webpackChunkName: "invite" */ '../views/kingCall.vue')
  },
  {
    path: '/farmyard',
    name: 'farmyard',
    component: () => import(/* webpackChunkName: "invite" */ '../views/farmyard.vue')
  },
  {
    path: '/klp',
    name: 'Klp',
    component: () => import(/* webpackChunkName: "invite" */ '../views/klp.vue'),
    meta: {
      // title: '快乐派短视频'
    }
  },
  {
    path: '/xyxq',
    name: 'xyxq',
    component: () => import(/* webpackChunkName: "invite" */ '../views/xyxq.vue')
  },
  {
    path: '/qianyan',
    name: 'qianyan',
    meta: { title: '千言ai分享' },
    component: () => import(/* webpackChunkName: "invite" */ '../views/qianyan.vue')
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
export default router
